
<script setup lang="ts">
const featureFlags = useCookie('featureFlags', {
  default: () => ({
    'DOCS': false,
    'GROUP': false,
    'USER': false,
  }),
});
console.log('featureFlags', featureFlags.value);

const enableFeature = (name: string) => {
  const newFlags = Object.assign({}, featureFlags.value, {
    [name]: true,
  });
  featureFlags.value = newFlags;
  console.table(featureFlags.value);
};

const disableFeature = (name: string) => {
  const newFlags = Object.assign({}, featureFlags.value, {
    [name]: false,
  });
  featureFlags.value = newFlags;
  console.table(featureFlags.value);
};

const toggleFlag = (name: string) => {
  if (featureFlags.value[name as keyof typeof featureFlags.value] === true) {
    disableFeature(name);
  } else {
    enableFeature(name);
  }
};

const reload = () => {
  window.location.reload();
};
</script>

<template>
  <div class="feature-flag-manager">
    <header>
      <h4>Feature flags</h4>
      <button @click="reload">
        reload
      </button>
    </header>
    <ul class="flags">
      <li
        v-for="(enabled, flagName) in featureFlags"
        :key="flagName"
        class="flag"
      >
        <div class="state">
          {{enabled ? '🟢' : '🔴'}}
        </div>
        <span class="name">{{flagName}}</span>
        <button
          class="toggle"
          @click.prevent="() => {
            toggleFlag(flagName)
          }"
        >
          {{!enabled ? 'Enable' : 'Disable'}}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
  .feature-flag-manager {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 0.75em;
    font-family: monospace;
    z-index: 1000;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5em;

      h4 {
        margin: 0;
        color: white;
      }
    }

    .flags {
      list-style: none;
      padding: 0;
      margin: 0;

      .flag {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        .state {
          width: 1.5em;
          margin-right: 0.5em;
        }

        .name {
          margin-right: 1.5em;
        }
      }
    }
  }
</style>